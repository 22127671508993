import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://f88aaf0957ebc0c4328309b4eccaf0c9@o4506579891847168.ingest.us.sentry.io/4507123054018560",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 0.5,
  sampleRate: 0.5,
  integrations: [new Sentry.BrowserTracing()],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});